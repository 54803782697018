<template>
  <modal
    :show="modalStatus"
    body-classes="p-0"
    modal-classes="modal-dialog-centered modal-lg"
    @close="onModalClosed"
  >
    <card
      type="secondary"
      shadow
      header-classes="bg-white pb-5"
      body-classes="px-lg-5 py-lg-5"
      class="border-0"
    >
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="onSaved" class="position-relative">
          <base-loader
            v-if="
              $wait.is('fetchCenters') ||
                $wait.is('createUser') ||
                $wait.is('fetchUser') ||
                $wait.is('updateUser')
            "
          />
          <ValidationProvider
            :name="t('username')"
            rules="required|min:3"
            v-slot="{ errors }"
          >
            <base-input
              :label="t('username')"
              required
              v-model="user.username"
              :error="errors[0]"
            >
            </base-input>
          </ValidationProvider>
          <ValidationProvider
            :name="t('full_name')"
            rules="required"
            v-slot="{ errors }"
          >
            <base-input
              :label="t('full_name')"
              required
              v-model="user.full_name"
              :error="errors[0]"
            >
            </base-input>
          </ValidationProvider>
          <ValidationProvider
            :name="t('password')"
            :rules="passwordValidationRules"
            v-slot="{ errors }"
          >
            <base-input
              :label="t('password')"
              :required="!id"
              type="password"
              v-model="user.password"
              :error="errors[0]"
            >
            </base-input>
          </ValidationProvider>
          <div class="form-group">
            <label class="form-control-label">
              {{ t("level") }}
            </label>
            <multiselect
              v-model="user.level"
              :options="['MASTER', 'MANAGER', 'VIEWER', 'EDITOR']"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ t(`level_${option}`) }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ t(`level_${option}`) }}
              </template>
            </multiselect>
          </div>
          <div class="form-group" v-if="user.level !== 'MASTER'">
            <label class="form-control-label">
              {{ t("centers") }}
            </label>
            <ValidationProvider
              :name="t('centers')"
              rules="required"
              v-slot="{ errors }"
            >
              <multiselect
                v-model="user.centers"
                :multiple="true"
                label="name"
                track-by="id"
                :options="centers"
                :group-select="true"
              >
              </multiselect>
              <base-alert type="warning" v-if="errors[0]" class="mt-2">
                {{ errors[0] }}
              </base-alert>
            </ValidationProvider>
          </div>
          <base-input :label="t('phone')" type="tel" v-model="user.phone">
          </base-input>
          <ValidationProvider
            :name="t('email')"
            rules="email"
            v-slot="{ errors }"
          >
            <base-input
              :label="t('email')"
              type="email"
              v-model="user.email"
              :error="errors[0]"
            >
            </base-input>
          </ValidationProvider>
          <div class="d-flex justify-content-between">
            <base-button
              outline
              type="default"
              class="my-4"
              @click="onModalClosed"
            >
              {{ t("button_cancel") }}
            </base-button>
            <base-button
              type="success"
              class="my-4"
              nativeType="submit"
              :disabled="invalid"
            >
              {{ t("button_save") }}
            </base-button>
          </div>
        </form>
      </ValidationObserver>
    </card>
  </modal>
</template>
<script>
export default {
  name: "user-modal",
  data() {
    return {
      user: {
        username: "",
        password: "",
        full_name: "",
        phone: "",
        email: "",
        level: "EDITOR",
        centers: [],
      },
      centers: [],
    };
  },
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: false,
    },
  },
  computed: {
    passwordValidationRules() {
      return this.id ? "min:5" : "required|min:5";
    },
  },
  watch: {
    modalStatus: function(status) {
      if (status && !!this.id) {
        this.getUser();
      }
    },
  },
  methods: {
    onModalClosed() {
      this.$emit("update:modalStatus", false);
      this.resetForm();
    },

    onSaved() {
      const user = {
        ...this.user,
        centers: this.user.centers.map((_c) => _c.id),
      };

      if (!this.id) {
        this.add(user);
      } else {
        this.update(user);
      }
    },

    getCenters() {
      this.$wait.start("fetchCenters");
      this.$store
        .dispatch("center/FETCH_CENTERS")
        .then((centers) => {
          this.centers = centers;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchCenters");
        });
    },

    getUser() {
      this.$wait.start("fetchAppUser");
      this.$store
        .dispatch("user/FETCH_APP_USER", this.id)
        .then((user) => {
          this.user = user;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchAppUser");
        });
    },

    add(user) {
      this.$wait.start("createUser");
      this.$store
        .dispatch("user/ADD_USER", user)
        .then(() => {
          this.$emit("saved");
          this.showSuccess(this.t("message_user_added"));
          this.onModalClosed();
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("createUser");
        });
    },

    update(user) {
      this.$wait.start("updateUser");
      this.$store
        .dispatch("user/UPDATE_USER", user)
        .then(() => {
          this.$emit("saved");
          this.showSuccess(this.t("message_user_updated"));
          this.onModalClosed();
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("updateUser");
        });
    },

    resetForm() {
      this.user = {
        username: "",
        password: "",
        full_name: "",
        phone: "",
        email: "",
        level: "EDITOR",
        centers: [],
      };
    },
  },
  created() {
    this.getCenters();
  },
};
</script>
