<template>
  <div>
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-12">
            <h1 class="display-3 text-white">{{ t("users") }}</h1>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-12">
          <card shadow type="secondary">
            <base-loader
              v-if="$wait.is('fetchCenters') || $wait.is('deleteUser')"
            />
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-12 text-right">
                  <base-button
                    outline
                    type="primary"
                    icon="ni ni-fat-add"
                    @click="openUserModal()"
                  >
                    {{ t("new_user") }}
                  </base-button>
                </div>
              </div>
            </div>
            <base-table
              class="table align-items-center table-flush"
              tbody-classes="list"
              :data="users"
            >
              <template slot="columns">
                <th>{{ t("username") }}</th>
                <th>{{ t("full_name") }}</th>
                <th>{{ t("level") }}</th>
                <th>{{ t("centers") }}</th>
                <th>{{ t("actions") }}</th>
              </template>

              <template slot-scope="{ row }">
                <th>
                  {{ row.username }}
                </th>
                <td>
                  {{ row.full_name }}
                </td>
                <th>
                  {{ t("level_" + row.level) }}
                </th>
                <td>
                  <ul class="list-group" v-if="row.level !== 'MASTER'">
                    <li
                      class="list-group-item py-1"
                      v-for="(center, keyCenter) in row.centers"
                      :key="keyCenter"
                    >
                      {{ center.name }}
                    </li>
                  </ul>
                  <h4 v-else class="text-success">
                    <i class="ni ni-check-bold mr-1"></i>
                    <span>{{ t("all") }}</span>
                  </h4>
                </td>
                <td>
                  <base-button
                    type="primary"
                    outline
                    icon="ni ni-ruler-pencil"
                    size="sm"
                    @click="openUserModal(row.id)"
                  ></base-button>
                  <base-button
                    outline
                    type="warning"
                    icon="ni ni-fat-remove"
                    size="sm"
                    @click="openDeleteConfirmModal(row.id)"
                  ></base-button>
                </td>
              </template>
            </base-table>
          </card>
        </div>
      </div>
    </div>
    <user-modal
      :modalStatus.sync="userFormModal"
      :id="selectedUserId"
      @saved="getUsers"
    />
    <delete-confirm-modal
      :modalStatus.sync="userDeleteModal"
      :id="selectedUserId"
      @confirm="deleteUser"
    />
  </div>
</template>
<script>
import UserModal from "./UserModal/UserModal";
import DeleteConfirmModal from "../components/Modals/deleteConfirmModal";

export default {
  name: "user-profile",
  data() {
    return {
      users: [],
      userFormModal: false,
      userDeleteModal: false,
      selectedUserId: null,
    };
  },
  components: {
    UserModal,
    DeleteConfirmModal,
  },
  methods: {
    getUsers() {
      this.$wait.end("fetchUsers");
      this.$store
        .dispatch("user/FETCH_USERS")
        .then((users) => {
          this.users = users;
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("fetchUsers");
        });
    },
    openUserModal(id = null) {
      this.selectedUserId = id;
      this.userFormModal = true;
    },
    openDeleteConfirmModal(id = null) {
      this.selectedUserId = id;
      this.userDeleteModal = true;
    },
    deleteUser() {
      this.$wait.end("deleteUser");
      this.$store
        .dispatch("user/DELETE_USER", this.selectedUserId)
        .then(() => {
          this.showSuccess(this.t("message_deleted"));
          this.getUsers();
        })
        .catch((err) => {
          this.showError(err);
        })
        .finally(() => {
          this.$wait.end("deleteUser");
        });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>
